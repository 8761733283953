import PlausibleProvider from 'next-plausible';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { Insights } from '~/components/Insights';
import SeoHeader from '~/components/seo/SeoHeader';
import { usePageview } from '~/hooks/analytics/usePageView';
import { useBarebonesRouteDetection } from '~/hooks/performance/useBarebonesRouteDetection';
import { useHackTimer } from '~/hooks/user-experience/useHackTimer';
import '~styles/globals.css';

// --------------------------------- DYNAMIC IMPORTS
const IGApp = dynamic<any>(() => import('../components/IGApp').then((mod) => mod.default), {
  ssr: false,
});

// --------------------------------- APP
function InternetGameApp({ Component, pageProps }: AppProps) {
  useHackTimer();
  usePageview();

  const isBarebonesRoute = useBarebonesRouteDetection();

  return (
    <div>
      <SeoHeader {...pageProps} />
      <Insights />
      <PlausibleProvider domain='internet.game'>
        {isBarebonesRoute ? (
          <Component {...pageProps} />
        ) : (
          <IGApp Component={Component} pageProps={pageProps} />
        )}
      </PlausibleProvider>
    </div>
  );
}
export default InternetGameApp;
