/**
 * This file contains the implementation of internet game business analytics.
 * The implementation is based on the Segment's Analytics.js library.
 * All the information about the events and their properties can be found here: https://www.notion.so/internetgame/Analytics-Tracking-Plan-cd84416abb53461cb58ed5b48a5162e9?pvs=4
 * Main stakeholders: product, commercialzation
 */

import { RudderAnalytics } from '@rudderstack/analytics-js';

// ------------------------------- Community Events -------------------------------
type BaseCommunity = {
  communityId: string;
  communityName: string;
  communityURL: string;
  communityType: string;
};

type CommunityCreationStartedEvent = Record<string, never>;

type CommunityCreatedEvent = BaseCommunity & {
  gating: string;
  backgroundUploaded: boolean;
  logoUploaded: boolean;
  plan: string;
};
type CommunityCustomizedEvent = BaseCommunity & {
  fieldsChanged: string[];
};
type CommunityLeftEvent = BaseCommunity & {
  activeSeats: number;
};
type CommunityJoindEvent = BaseCommunity & {
  inviteMethod: string;
  activeSeats: number;
  role: string;
};
type CommunityEntered = BaseCommunity;
type CommunityInviteSent = BaseCommunity & {
  invitesSentCount: number;
  inviteeEmails: string[];
};
type CommunityUserRemoved = BaseCommunity & {
  emailOfRemovedUser: string;
  activeSeats: number;
};

type CopyInviteLink = {
  source: 'dashboard' | 'community' | 'room';
};

// ------------------------------- Billing Events -------------------------------
type ClickedSubscribe = {
  location: 'pricing' | 'onboarding' | 'billing';
  subscription_type: string;
};

// ------------------------------- Match Events -------------------------------
type BaseMatch = {
  gameName: string;
  roomId: string;
  roomName: string;
  roomType: 'room' | 'tournament';
};
type MatchStartedEvent = BaseMatch & {
  matchStartTime: number;
};
type MatchPlayedEvent = BaseMatch & {
  matchId: string;
  avgFps: number;
  avgLatency: number;
  totalPlayers: number;
  matchStartTime: number;
  matchEndTime: number;
};
type MatchFailed = BaseMatch & {
  matchId: string;
  matchGroupId: string;
  gameVersion: string;
  reason: string;
};

// ------------------------------- Room Events -------------------------------
type BaseRoom = {
  roomId: string;
  roomName: string;
  isPublic: boolean;
  roomType: 'room' | 'tournament';
};

type GameSelectorClicked = {
  roomId: string;
  roomName: string;
};

type GameClicked = {
  roomId: string;
  roomName: string;
  gameName: string;
};

type FailedCheck = {
  reason: string;
};

// ------------------------------- User Events -------------------------------
type UserSignUpStartedEvent = {
  signupMethod: string;
  source: string;
};
type UserEmailVerifiedEvent = {
  source: string;
  signupMethod: string;
  emailVerificationTime: number;
  email: string;
};
type UserSignedInEvent = {
  signinTime: number;
  signinMethod: string;
};
type UserSignedOutEvent = {
  signoutTime: number;
  signinTime: number;
};
type UserSignedInFailedEvent = {
  signinTime: number;
  signinMethod: string;
  error: string;
};
type BaseUserProfile = {
  avatarUploaded: boolean;
  displayName: string;
};
type UserProfileCreatedEvent = BaseUserProfile & { walletConnected: boolean };

// ------------------------------- Support Events -------------------------------
type SupportIntiatedEvent = {
  startTime: number;
  page: string;
};
type SupportEndedEvent = {
  endTime: number;
};

interface IAnalytics {
  // ------------------------------- Helpers -------------------------------
  resetGroup(): Promise<void>;

  // ------------------------------- Identify & Group & Page Events -------------------------------
  identifyUser: (userId: string, username: string, email: string) => Promise<void>;
  group(communityId: string, communityName: string, communityType: string): Promise<void>;
  page(pageName: string): Promise<void>;

  // ------------------------------- Landing Page Events -------------------------------
  callToActionClicked: () => Promise<void>;
  outboundLinkClicked: (link: string) => Promise<void>;

  // ------------------------------- Global Platform Events -------------------------------
  copyInviteLink: (event: CopyInviteLink) => Promise<void>;

  // ------------------------------- Community Events -------------------------------
  communityCreationStarted: (event: CommunityCreationStartedEvent) => Promise<void>;
  communityCreated: (community: CommunityCreatedEvent) => Promise<void>;
  communityCustomized: (community: CommunityCustomizedEvent) => Promise<void>;
  communityLeft: (community: CommunityLeftEvent) => Promise<void>;
  communityDeleted: (community: BaseCommunity) => Promise<void>;
  communityEntered: (community: CommunityEntered) => Promise<void>;
  communityJoined: (community: CommunityJoindEvent) => Promise<void>;
  communityInviteSent: (community: CommunityInviteSent) => Promise<void>;
  communityUserRemoved: (community: CommunityUserRemoved) => Promise<void>;

  // ------------------------------- Billing Events -------------------------------
  viewedBilling: () => Promise<void>;
  clickedSubscribe: (event: ClickedSubscribe) => Promise<void>;

  // ------------------------------- Match Events -------------------------------
  matchStarted: (match: MatchStartedEvent) => Promise<void>;
  matchPlayed: (match: MatchPlayedEvent) => Promise<void>;
  matchFailed: (match: MatchFailed) => Promise<void>;

  // ------------------------------- Room Events -------------------------------
  roomCreated: (room: BaseRoom) => Promise<void>;
  roomJoined: (room: BaseRoom & BaseCommunity) => Promise<void>;
  gameSelectorClicked: (event: GameSelectorClicked) => Promise<void>;
  gameClicked: (event: GameClicked) => Promise<void>;
  voiceChatEnabled: () => Promise<void>;
  failedCheck: (event: FailedCheck) => Promise<void>;

  // ------------------------------- User Events -------------------------------
  userSignUpStarted: (user: UserSignUpStartedEvent) => Promise<void>;
  userEmailVerified: (user: UserEmailVerifiedEvent) => Promise<void>;
  userSignedIn: (user: UserSignedInEvent) => Promise<void>;
  userSignedOut: (user: UserSignedOutEvent) => Promise<void>;
  userSignedInFailed: (user: UserSignedInFailedEvent) => Promise<void>;
  userProfileCreated: (user: UserProfileCreatedEvent) => Promise<void>;
  userProfiledUpdated: (user: BaseUserProfile) => Promise<void>;
  userProfileDeleted: (user: BaseUserProfile) => Promise<void>;
  userConnectionRelayed: (user: BaseUserProfile) => Promise<void>;

  // ------------------------------- Support Events -------------------------------
  supportInitiated: (support: SupportIntiatedEvent) => Promise<void>; // not wired up yet
  supportEnded: (support: SupportEndedEvent) => Promise<void>; // not wired up yet
}

export class AnalyticsClientSingleton implements IAnalytics {
  private static instance: AnalyticsClientSingleton;
  private client: RudderAnalytics;

  public static getInstance(): AnalyticsClientSingleton {
    if (!AnalyticsClientSingleton.instance) {
      AnalyticsClientSingleton.instance = new AnalyticsClientSingleton();
    }
    return AnalyticsClientSingleton.instance;
  }

  private constructor() {
    const writeKey = process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY || '';
    const rudderAnalytics = new RudderAnalytics();
    rudderAnalytics.load(writeKey, `https://${window.location.hostname}/analytics`, {
      configUrl: `https://${window.location.hostname}/config`,
      pluginsSDKBaseURL: `https://${window.location.hostname}/plugins/v3/modern/plugins`,
    });
    this.client = rudderAnalytics;
    // this.client = AnalyticsBrowser.load(
    //   { writeKey },
    //   {
    //     integrations: {
    //       'Segment.io': {
    //         apiHost: `analytics.internet.game/v1`,
    //       },
    //     },
    //   }
    // );
    // this.client.ready(() => {
    //   const key = process.env.NEXT_PUBLIC_POSTHOG_KEY || '';
    //   if (key !== '') {
    //     posthog.init(key, {
    //       api_host: `${window.location.origin}/ingest`,
    //       // @ts-ignore
    //       segment: this.client.instance,
    //       capture_pageview: false,
    //       loaded: () => this.client.page(),
    //       session_recording: {
    //         blockClass: 'gl-block',
    //       },
    //     });
    //   }
    // });
  }

  // ------------------------------- Helpers -------------------------------
  private async getGroup(): Promise<{
    communityId: string | null | undefined;
    communityName?: string;
    communityType?: string;
  }> {
    const g = this.client.getGroupTraits();
    return {
      communityId: this.client.getGroupId(),
      communityName: (g?.communityName as string) || '',
      communityType: (g?.communityType as string) || '',
    };
  }

  private async getUser(): Promise<{
    userId: string | null | undefined;
    username?: string;
    email?: string | null | undefined;
  }> {
    const user = this.client.getUserTraits();
    return {
      userId: this.client.getUserId(),
      username: (user?.username as string) || '',
      email: (user?.email as string) || '',
    };
  }

  private async trackWithUserAndGroup(eventName: string, properties: any): Promise<void> {
    const g = await this.getGroup();
    const u = await this.getUser();
    await this.client.track(eventName, {
      ...u,
      ...g,
      ...properties,
    });
  }

  private async trackWithUser(
    eventName: string,
    properties: any,
    subscribe?: boolean
  ): Promise<void> {
    const u = await this.getUser();
    if (subscribe) {
      await this.client.track(
        eventName,
        {
          ...u,
          ...properties,
        },
        {
          context: {
            messaging_subscriptions: [
              {
                key: u.email,
                type: 'EMAIL',
                status: 'SUBSCRIBED',
              },
            ],
          },
        }
      );
    } else {
      await this.client.track(eventName, {
        ...u,
        ...properties,
      });
    }
  }

  async resetGroup(): Promise<void> {
    const userId = this.client.getUserId() || '';
    const userTraits = this.client.getUserTraits();
    this.client.reset();
    this.client.identify(userId, userTraits);
  }

  // ------------------------------- Identify & Group & Page Events -------------------------------
  async identifyUser(userId: string, username: string, email: string): Promise<void> {
    await this.client.identify(userId, {
      name: username,
      username,
      email,
    });
  }

  async group(communityId: string, communityName: string, communityType: string): Promise<void> {
    await this.client.group(communityId, {
      communityName,
      name: communityName,
      communityId,
      communityType,
    });
  }

  async page(pageName: string): Promise<void> {
    let category = 'home';
    if (pageName.includes('/blog')) {
      category = 'blog';
    }
    await this.client.page(category, pageName);
  }

  // ------------------------------- Landing Page Events -------------------------------
  async callToActionClicked(): Promise<void> {
    await this.client.track('Call To Action Clicked');
  }

  async outboundLinkClicked(link: string): Promise<void> {
    await this.client.track('Outbound Link Clicked', {
      link,
    });
  }

  // ------------------------------- Global Platform Events -------------------------------
  async copyInviteLink({ source }: CopyInviteLink): Promise<void> {
    await this.trackWithUserAndGroup('Invite Link', {
      source,
    });
  }

  // ------------------------------- Community Events -------------------------------
  async communityCreationStarted({}: CommunityCreationStartedEvent): Promise<void> {
    await this.trackWithUser('Community Creation Started', {});
  }

  async communityCreated({
    gating,
    logoUploaded,
    backgroundUploaded,
    plan,
    communityId,
    communityName,
    communityURL,
  }: CommunityCreatedEvent): Promise<void> {
    await this.trackWithUser('Community Created', {
      communityId,
      communityName,
      gating,
      logoUploaded,
      backgroundUploaded,
      plan,
      communityURL,
    });
  }

  async communityCustomized({
    fieldsChanged,
    communityId,
    communityName,
    communityType,
  }: CommunityCustomizedEvent): Promise<void> {
    if (fieldsChanged.find((field) => field === 'name')) {
      await this.group(communityId || '', communityName || '', communityType || '');
    }
    await this.trackWithUserAndGroup('Community Customized', {
      fieldsChanged,
    });
  }

  async communityLeft({
    communityId,
    communityName,
    communityURL,
    activeSeats,
  }: CommunityLeftEvent): Promise<void> {
    await this.trackWithUser('Community Left', {
      communityId,
      communityName,
      communityURL,
      activeSeats,
    });
  }

  async communityDeleted({
    communityId,
    communityName,
    communityURL,
    communityType,
  }: BaseCommunity): Promise<void> {
    await this.communityEntered({ communityId, communityName, communityURL, communityType });
    await this.trackWithUserAndGroup('Community Deleted', {});
    this.resetGroup();
  }

  async communityEntered({
    communityId,
    communityName,
    communityURL,
    communityType,
  }: CommunityEntered): Promise<void> {
    const groupId = this.client.getGroupId() || '';
    if (!groupId || groupId !== communityId) {
      this.resetGroup();
      await this.group(communityId || '', communityName || '', communityType || '');
      await this.trackWithUserAndGroup('Community Entered', { communityURL });
    }
  }

  async communityJoined({
    activeSeats,
    inviteMethod,
    communityName,
    communityId,
    communityURL,
    role,
    communityType,
  }: CommunityJoindEvent): Promise<void> {
    await this.trackWithUser('Community Joined', {
      communityId,
      communityName,
      communityURL,
      activeSeats,
      inviteMethod,
      role,
      communityType,
    });
  }

  async communityInviteSent({
    communityId,
    communityName,
    invitesSentCount,
    inviteeEmails,
    communityURL,
    communityType,
  }: CommunityInviteSent): Promise<void> {
    await this.communityEntered({ communityId, communityName, communityURL, communityType });
    await this.trackWithUser('Community Invite Sent', {
      invitesSentCount,
      inviteeEmails,
    });
  }

  async communityUserRemoved({
    communityId,
    communityName,
    activeSeats,
    emailOfRemovedUser,
    communityURL,
    communityType,
  }: CommunityUserRemoved): Promise<void> {
    await this.communityEntered({ communityId, communityName, communityURL, communityType });
    await this.trackWithUserAndGroup('Community User Removed', {
      activeSeats,
      emailOfRemovedUser,
    });
  }

  // ------------------------------- Billing Events -------------------------------
  async viewedBilling(): Promise<void> {
    await this.trackWithUserAndGroup('Viewed Billing', {});
  }

  async clickedSubscribe({ location, subscription_type }: ClickedSubscribe): Promise<void> {
    console.log('CLICKED SUBSCRIBE!!!', location, subscription_type);
    await this.trackWithUserAndGroup('Clicked Subscribe', {
      location,
      subscription_type,
    });
  }

  // ------------------------------- Match Events -------------------------------
  async matchStarted({
    gameName,
    roomId,
    roomName,
    matchStartTime,
    roomType,
  }: MatchStartedEvent): Promise<void> {
    await this.trackWithUserAndGroup('Match Started', {
      gameName,
      roomId,
      roomName,
      matchStartTime,
      roomType,
    });
  }

  async matchPlayed({
    gameName,
    roomId,
    roomName,
    avgFps,
    avgLatency,
    totalPlayers,
    matchStartTime,
    matchEndTime,
    matchId,
    roomType,
  }: MatchPlayedEvent): Promise<void> {
    await this.trackWithUserAndGroup('Match Played', {
      matchId,
      gameName,
      roomId,
      roomName,
      avgFps,
      avgLatency,
      totalPlayers,
      matchStartTime,
      matchEndTime,
      roomType,
    });
  }

  async matchFailed({
    gameName,
    gameVersion,
    roomId,
    roomName,
    matchId,
    matchGroupId,
    reason,
    roomType,
  }: MatchFailed): Promise<void> {
    await this.trackWithUserAndGroup('Match Failed', {
      gameVersion,
      gameName,
      matchId,
      matchGroupId,
      roomId,
      roomName,
      reason,
      roomType,
    });
  }

  // ------------------------------- Room Events -------------------------------
  async roomCreated({ roomId, roomName, isPublic, roomType }: BaseRoom): Promise<void> {
    await this.trackWithUserAndGroup('Room Created', {
      roomId,
      roomName,
      isPublic,
      roomType,
    });
  }

  async roomJoined({
    roomId,
    roomName,
    isPublic,
    communityId,
    communityName,
    communityURL,
    communityType,
    roomType,
  }: BaseRoom & BaseCommunity): Promise<void> {
    await this.communityEntered({ communityId, communityName, communityURL, communityType });
    await this.trackWithUserAndGroup('Room Joined', {
      roomId,
      roomName,
      isPublic,
      roomType,
    });
  }

  async gameSelectorClicked({ roomId, roomName }: GameSelectorClicked): Promise<void> {
    await this.trackWithUserAndGroup('Game selector clicked', {
      roomId,
      roomName,
    });
  }

  async gameClicked({ gameName, roomId, roomName }: GameClicked): Promise<void> {
    await this.trackWithUserAndGroup('Game clicked', {
      gameName,
      roomId,
      roomName,
    });
  }

  async voiceChatEnabled(): Promise<void> {
    await this.trackWithUserAndGroup('Voice Chat Enabled', {});
  }

  async failedCheck({ reason }: FailedCheck): Promise<void> {
    await this.trackWithUserAndGroup('Failed Check', {
      reason,
    });
  }

  // ------------------------------- User Events -------------------------------
  async userSignUpStarted({ signupMethod, source }: UserSignUpStartedEvent): Promise<void> {
    await this.client.track('User Sign Up Started', {
      signupMethod,
      source,
    });
  }

  async userEmailVerified({
    source,
    signupMethod,
    emailVerificationTime,
  }: UserEmailVerifiedEvent): Promise<void> {
    await this.client.track('User Email Verified', {
      source,
      signupMethod,
      emailVerificationTime,
    });
  }

  async userSignedIn({ signinTime, signinMethod }: UserSignedInEvent): Promise<void> {
    await this.trackWithUser('User Signed In', {
      signinTime,
      signinMethod,
    });
  }

  async userSignedOut({ signoutTime, signinTime }: UserSignedOutEvent): Promise<void> {
    await this.trackWithUser('User Signed Out', {
      signoutTime,
      signinTime,
    });
    this.client.reset();
  }

  async userSignedInFailed({
    signinTime,
    signinMethod,
    error,
  }: UserSignedInFailedEvent): Promise<void> {
    await this.client.track('User Signed In Failed', {
      signinTime,
      signinMethod,
      error,
    });
  }

  async userProfileCreated({
    avatarUploaded,
    displayName,
    walletConnected,
  }: UserProfileCreatedEvent): Promise<void> {
    await this.trackWithUser(
      'User Profile Created',
      {
        avatarUploaded,
        displayName,
        walletConnected,
      },
      true
    );
  }

  async userProfiledUpdated(user: BaseUserProfile): Promise<void> {
    await this.trackWithUser('User Profile Updated', user);
  }

  async userProfileDeleted(user: BaseUserProfile): Promise<void> {
    await this.trackWithUser('User Profile Deleted', user);
    this.client.reset();
  }

  async userConnectionRelayed(): Promise<void> {
    await this.trackWithUser('User Connection Relayed', {});
  }

  // ------------------------------- Support Events -------------------------------
  async supportInitiated({ startTime, page }: SupportIntiatedEvent): Promise<void> {
    await this.trackWithUserAndGroup('Support Initiated', {
      startTime,
      page,
    });
  }

  async supportEnded({ endTime }: SupportEndedEvent): Promise<void> {
    await this.trackWithUserAndGroup('Support Ended', {
      endTime,
    });
  }
}
