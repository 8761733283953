import { SpeedInsights } from '@vercel/speed-insights/react';
import { useRouter } from 'next/router';

interface InsightsProps {}

export const Insights: React.FC<InsightsProps> = ({}) => {
  const router = useRouter();

  return <SpeedInsights route={router.pathname} />;
};
