import { useEffect } from 'react';

// UNCOMMENT THIS TO TEST WITH OR WITHOUT HACKTIMER
// let startTime = Date.now();

export const useHackTimer = () => {
  useEffect(() => {
    // COMMENT THIS OUT TO DISABLE HACKTIMER
    // @ts-ignore
    import('hacktimer').then((mod) => {
      // @ts-ignore
      window.hacktimer = mod;
      // UNCOMMENT THIS TO TEST HACKTIMER
      // setInterval(() => {
      //   console.log('Time since last frame', Date.now() - startTime);
      //   startTime = Date.now();
      // }, 10000);
    });
    // UNCOMMENT THIS TO TEST WITHOUT HACKTIMER
    // setInterval(() => {
    //   const diff = Date.now() - startTime;
    //   console.log('Time since last frame', diff);
    //   startTime = Date.now();
    //   diff > 10000 + 1000 && console.log('Notice drift in the timer, diff compared to ~10s');
    // }, 10000);
  }, []);
};
