import Head from 'next/head';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

// This component is necessary because crawlers are not able to view the updated data when it is modified in the head tag.
// For pages like the join page or community page, this component dynamically sets the head tag to be consumed by _app.tsx to display the correct values

interface SeoHeaderProps {
  communityName?: string;
}

export const SeoHeader: React.FC<SeoHeaderProps> = ({ communityName }) => {
  const router = useRouter();
  const pathname = router.pathname;

  const seoData = useMemo(() => {
    let title;
    let description;
    if (communityName) {
      if (pathname.endsWith('/join')) {
        title = `Join ${communityName}  on Internet Game`;
      } else {
        title = `${communityName} on Internet Game`;
      }
      description = 'Join this community to play games!';
      return {
        title,
        description,
      };
    } else {
      title = 'Internet Game - Free Online Games, Play With Friends!';
      description =
        'The easiest way to play games with friends online. Just share link, choose a game and click play.';
      return {
        title,
        description,
      };
    }
  }, [communityName, pathname]);

  return (
    <Head>
      <title>{seoData?.title}</title>
      <meta charSet='UTF-8' />

      <meta name='viewport' content='width=device-width, initial-scale=1.0' />

      <link rel='icon' type='image/png' href='/common/brand/favicon.png' />

      <link
        rel='preload'
        href='/fonts/SpaceGrotesk/space-grotesk-variable.woff2'
        as='font'
        type='font/woff2'
        crossOrigin='anonymous'
      />

      <meta name='robots' content='index, follow' />
      <meta name='rating' content='general' />

      <meta property='og:title' content={seoData?.title} key='og-title' />
      <meta name='description' content={seoData?.description} />
      <meta property='og:description' content={seoData?.description} key='og-description' />

      <meta property='og:url' content='https://internet.game/' key='og-url' />
      <meta property='og:type' content='website' key='og-type' />
      <meta property='og:image' content='https://internet.game/common/meta/og.png' key='og-image' />

      <meta name='twitter:card' content='summary_large_image' />
      <meta property='twitter:domain' content='internet.game' />
    </Head>
  );
};

export default SeoHeader;
